.TargetPrintRaportAreaFrom {
    --BMX-RAPORT: #4c517d;
    font-size: 0.9rem;
    color: var(--BMX-black);
    font-weight: 400;

    .HeaderArea {
        width: 100%;
        padding-left: 2rem;
        display: flex;
        .LogoArea {
            margin-top: 1.5rem;
            width: 60%;
            .Logo {
                max-height: 4rem;
                margin-bottom: 0.5rem;
            }
            span {
                display: block;
            }
        }

        .DateArea {
            padding-right: 1rem;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            width: 40%;
            margin: 1.5rem 0;
            > span {
                text-align: center;
                &:last-child {
                    margin-top: 1rem;
                }
                > span,
                h5 {
                    display: block;
                    margin-right: 1rem;
                }
            }
        }
    }
    .HeaderEnd {
        margin-top: 2rem;
        height: 4rem;
        background-color: var(--BMX-RAPORT);
        display: flex;
        justify-content: flex-end;
        .InnerText {
            color: var(--BMX-RAPORT);
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 8rem;
            width: 10rem;
            height: 100%;
            font-size: large;
            font-weight: 400;
            max-width: 14rem;
            text-align: center;
            background: var(--BMX-white);
        }
    }
    .InfoArea {
        margin-top: 4rem;
        margin-right: 2rem;
        margin-left: 2rem;
        .Client {
            font-size: 1.1rem;
        }
        > div {
            display: inline-flex;
            flex-direction: column;
            &:nth-child(1) {
                width: 55%;
            }
            &:nth-child(2) {
                width: 45%;
            }
            > span {
                display: block;
                font-weight: 400;
            }
            > div {
                margin-bottom: 1rem;
                > span {
                    display: block;
                    &:nth-child(1) {
                        font-weight: 400;
                    }
                }
            }
        }
    }

    .DescriptionPrintArea {
        margin: 1rem 2rem 2rem 2rem;
        > div {
            > div {
                word-break: break-word;
                overflow: hidden;
                text-indent: 1rem;
                &:nth-child(1) {
                    background: var(--BMX-RAPORT);
                    padding: 0 0.5rem;
                    > span {
                        color: var(--BMX-white);
                    }
                }
                &:nth-child(2) {
                    border: 1px solid var(--BMX-grey);
                    padding: 0.2rem;
                    height: 6rem;
                }
            }
        }
    }

    .SummaryArea {
        background-color: var(--BMX-RAPORT);
        color: var(--BMX-white);
        width: 50%;
        height: 4rem;
        margin: 4rem 1.25rem 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        position: relative;
        &::after {
            content: attr(word-summary);
            display: block;
            margin: 0 0 0 auto;
            position: absolute;
            color: initial;
            bottom: -1.5rem;
            font-size: 0.8em;
            right: 0;
            text-align: end;
            width: max-content;
        }
    }
    .DatePrintArea {
        display: flex;
        position: absolute;
        left: 2rem;
        flex-direction: column;
        align-items: center;
        height: 4rem;
        justify-content: flex-end;
    }

    .Footer {
        position: absolute;
        bottom: 1rem;
        background: var(--BMX-RAPORT);
        text-align: center;
        color: var(--BMX-white);
        font-weight: 400;
        width: 100%;
        right: 0rem;
    }
    
    .TablePrintArea {
        font-size: 0.6rem;
        font-weight: 400;
        position: relative;
        width: 95%;
        margin: 0 auto;
        padding-top: 2rem;
        > * {
            width: 100%;
        }

        .TableFooter {
            width: 100%;
            padding-top: 0.5rem;
            font-size: 0.8rem;
            display: flex;
            justify-content: space-between;
        }
        table {
            border: 1px solid var(--BMX-RAPORT);
        }

        thead {
            white-space: nowrap;
            background: var(--BMX-RAPORT);
            color: var(--BMX-white);
            text-align: start;
            vertical-align: middle;
        }

        tbody,
        thead {
            height: 2rem;
            * {
                vertical-align: middle;
            }
        }

        th,
        td {
            &:nth-child(1) {
                padding-left: 1rem;
            }
            padding: 1rem 1rem 1rem 0;
        }

        td {
            &:nth-child(1) {
                padding-left: 1.2rem;
            }
        }
    }
}
