.settledPerson{
  color: var(--BMX-dark-grey);
  font-size: 0.8rem;
  position: absolute;
  bottom: 0;
}

.currenNumber {
 background: var(--BMX-diarrhea-color);
 
}
