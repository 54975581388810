.OperationLockFormStepOne {
  display: flex;
  justify-content: flex-start !important;
  .summary {
    font-size: 0.8rem;
    color: var(--BMX-grey);
    .area {
      overflow: auto;
      max-height: 200px;
    }
  }
  
}
