.SecondaryTable {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  

  >.SearchBarArea {
    width: 100%;

    >.BMXSearchField {
      padding: 1.5rem 0;
      margin: 0 0 0 auto;
    }
  }

  >.PaginationTable {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 1.6rem;
  }

  >.ContentTable {
    position: relative;
    width: 100%;
    overflow: hidden !important;
    height: 100%;

    [Data-Body] {
      overflow-y: auto;
      overflow-x: hidden !important;
      width: 100%;
      height: 100%;
    }

    .Header {
      color: var(--BMX-table-header);
      font-size: 0.8rem;
      display: flex;
      border-bottom: 2.5px solid var(--BMX-primary);
      padding: 0 0.2rem;

      >div {
        display: flex;
        align-items: center;
        height: 48px;
        cursor: pointer;
        flex: 1 1 0;
        max-width: var(--width);
        min-width: var(--width);

        >b {
          cursor: pointer;
        }

        >div {
          position: relative;
          display: flex;
          align-items: center;

          &:hover {
            >.SortButton {
              opacity: 1;
            }
          }

          >.SortButton {
            position: absolute;
            right: -33px;
            opacity: 0;
            transition: opacity 0.4s linear;

            &.asc {
              animation: Asc 0.25s ease-out both;
            }

            &.des {
              animation: Dsc 0.25s ease-out both;
            }

            &.selected {
              opacity: 1;
            }
          }
        }
      }

      div {

        // margin-left: -10px;
        div {
          padding: 0 10px;
        }
      }
    }

    >div:nth-child(2) {
      border-top: none;
    }

    .Data {
      position: relative;
      display: flex;
      padding: 0.3rem 0.5rem;
      font-size: 0.8rem;
      overflow: hidden !important;
      border-top: thin solid var(--BMX-border-color);

      &.ARCHIVE {
        color: var(--BMX-grey);
      }

      .Record {
        margin: auto 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        flex: 1 1;
        min-width: var(--width);
        max-width: var(--width);
        &.DEACTIVATED{
          color: var(--BMX-grey);
        }
      }

      .text-lines-clamp {
        --numberOfLines: 4;

        >* {

          width: 100%;
          white-space: normal;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: var(--numberOfLines);
          /* number of lines to show */
          -webkit-box-orient: vertical;
        }

      }

      >* {
        display: flex;
        align-items: center;
        height: 48px;
        flex: 1 1 0;
        max-width: var(--width);
        min-width: var(--width);
      }

      &.select {
        cursor: pointer;
      }

      &.selected::before {
        position: absolute;
        content: ' ';
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: var(--BMX-primary);
        opacity: 0.3;
      }

      .AnimCircle {
        overflow: hidden;
        position: absolute;
        background: rgba(0, 0, 0, 0.171);
        transform: translate(-50%, -50%);
        pointer-events: none;
        border-radius: 50%;
        animation: anim 0.8s linear infinite;
      }

      .DataItem {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 70ch;
      }

      span {
        padding: 3px;
      }
    }
  }

  .dl-item.floating {
    width: 85%;
  }
}

@keyframes anim {
  0% {
    opacity: 1;
    width: 0px;
    height: 0px;
  }

  100% {
    opacity: 0;
    width: 100vmax;
    height: 100vmax;
  }
}

@keyframes Asc {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(180deg);
  }
}

@keyframes Dsc {
  0% {
    transform: rotateZ(180deg);
  }

  100% {
    transform: rotateZ(0deg);
  }
}