.nip-warning {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-top: 0.6rem;
  background-color: #ffa500;
  color: #fff;
  font-weight: bold;
  padding: 4px;
  border: 1px solid #ffa500;
  border-radius: 5px;
  font-size: 0.8rem;
}
