.TonerReleaseFormStepOne {
  .PlotButton {
    position: absolute;
    right: -33rem;
    top: 28.25rem;
  }
  .error-container {
    min-height: 20px; // Adjust the height based on your design
    display: flex;
    justify-content: center;
    font-size: 0.9em;
  }
  li {
    font-size: 0.9rem;
    margin-top: 0.3rem;
  }
  .TonerReleaseLeftSide {
    border-right: 1px solid var(--BMX-modal-grey);
  }
  .text-mini {
    font-size: 0.8rem;
  }
  .text-medium {
    font-size: 0.9rem;
  }
  .sharpInfo {
    display: flex;
    background-color: #e80016;
    border-radius:5px;
    padding:2px;
    color: white;
    font-weight: bold;
    .sn {
      padding-left: -15px !important;
    }
    .wheel {
      width: 10px;
      height: 10px;
      border-radius: 5px;
      margin-top: 4px;
      margin-right: 3px;
      background-color: white;
    }
  }
  .tonerInfo {
    display: flex;
    text-align: center;
    align-items: center;
    font-weight: bold;

    .sn {
      padding-left: -15px !important;
    }
    .wheel {
      width: 8px;
      height: 8px;
      border-radius: 5px;

      margin-right: 5px;
    }
  }
  .list {
    margin-left: 0;
    font-size: 0.95rem;
    padding-left: 0;
    color: rgba(0, 0, 0, 0.87);
    border-top: 1px solid var(--BMX-modal-grey);
  }

  .hold--button {
    background-color: #f8edd0;
    color: black;

    &.active {
      border: 2px solid rgba(25, 118, 210, 0.5);
    }
    &.not-active {
      background-color: #fff;
      color: black;
    }
  }
  .wydanie--button {
    &.active {
      background-color: var(--BMX-primary);
      color: var(--BMX-white) !important;
      border: 2px solid rgba(25, 118, 210, 0.5);
    }
    &.not-active {
      background-color: var(#f8edd0);
      color: var(--BMX-black) !important;
    }
  }

  .radioButtonRow {
    text-align: center;
  }
  .radioButton {
    display: flex;
  }
  .personResponsible {
    position: absolute;
    font-size: 0.8rem;
    bottom: 0;
  }
}
