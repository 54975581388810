.ConfirmInvoiceModalForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .img {
        width: 30%;       
    }
    .title {
        font-size: 30px;
        color: #595959;
    }
    .content {
        font-size: 20px;
        color: #545454;
    }
    .invoiceFrom{
        font-size: 0.9em;
        color: var(--BMX-error) !important;
        position: absolute;
        left: 3.5em;
        bottom: 6em
    } 
}