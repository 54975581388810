.SuppliesOtherKitModal{
    position: relative;
    padding-left:15%;
    padding-top: 1.5rem;
    &::before{
        content: " ";
        height: 100%;
        width: 15%;
        position: absolute;
        top:0;
        left: 0;
        background-color: rgba(0, 17, 39, 0.85);
    }
}